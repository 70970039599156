import { Box, IconButton, Button } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddNewClass from "../../components/i-course/classes/AddNewClass"
import { coursesList, categoryList, chaptersList, classesList } from "../../redux/icourseslice";
import SelectComponent from "../../components/Select"
import DeleteClassModal from "../../components/i-course/classes/DeleteClass";
import EditClassModel from "../../components/i-course/classes/EditClass";

const IcourseClasses = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const { loading, categories, totalRecords, courses, chapters, classes, addNewClassSuccessfully, deleteClassSuccessfully, editClassSuccessfully } = useSelector((state) => state.icourse);
  const [selectedClass, setSelectedClass] = useState(null);
  const [openNewClass, setOpenNewClass] = useState(false);
  const [openDeleteClass, setOpenDeleteClass] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [openEditClass, setOpenEditClass] = useState(false);
  const { currentUser } = useSelector((state) => state.users);

  useEffect(() => {
    document.title = "I Course - Chapters | Admin Panel";
    dispatch(categoryList());
  }, [])



  const handleCategoryChange = (event) => {
    const selectedUniId = event.target.value;
    setSelectedCategory(selectedUniId);
  };

  useEffect(() => {
    if (selectedCategory) {
      dispatch(coursesList({ selectedCategory }));
    }
  }, [selectedCategory])

  useEffect(() => {
    console.log("courses", courses)
  }, [courses])


  const handleCourseChange = (event) => {
    const selectedCourseId = event.target.value;
    setSelectedCourse(selectedCourseId);
  }

  useEffect(() => {
    if (selectedCourse) {
      dispatch(chaptersList({ "selectedCourseId": selectedCourse }));
    }
  }, [selectedCourse])

  const handleChaptersChange = (event) => {
    const selectedChapterId = event.target.value;
    setSelectedChapter(selectedChapterId);
  }

  useEffect(() => {
    if (selectedChapter) {
      dispatch(classesList({ "selectedChapterId": selectedChapter }));
    }
  }, [selectedChapter])



  const categoriesOptions = (categories || []).map((category) => ({
    value: category?._id,
    label: category?.title,
  }));


  const categoriesCourses = (courses || []).map((course) => ({
    value: course?._id,
    label: course?.title,
  }));


  const chaptersOptions = (chapters || []).map((chapter) => ({
    value: chapter?._id,
    label: chapter?.title,
  }));




  useEffect(() => {

    if (selectedChapter && (addNewClassSuccessfully || deleteClassSuccessfully || editClassSuccessfully)) {
      dispatch(classesList({ "selectedChapterId": selectedChapter }));
    }

  }, [addNewClassSuccessfully, deleteClassSuccessfully, editClassSuccessfully])


  const handleMenuClick = (event, selectedClass) => {
    setSelectedClass(null);
    setAnchorEl(event.currentTarget);
    setSelectedClass(selectedClass);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModalNewUnivesity = () => {
    setOpenNewClass(false);
  }

  const handleOpenModalNewUnivesity = () => {
    setOpenNewClass(true);
  }



  const handleCloseModalDeleteClass = () => {
    setOpenDeleteClass(false);

  }

  const handleOpenModalDeleteClass = () => {
    setOpenDeleteClass(true);
    handleMenuClose()
  }

  const handleOpenEditClass = () => {
    setOpenEditClass(true);
    handleMenuClose()
  }

  const handleCloseEditClass = () => {
    setOpenEditClass(false);
  }

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "title",
      headerName: "title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "files",
      headerName: "Download",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.files.map((file) => (
            <a key={file._id} href={file.url} download={file.title} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
              <Button variant="contained" color="primary">
                {file.title}
              </Button>
            </a>
          ))}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => {
            event.stopPropagation()
            handleMenuClick(event, params.row)
          }}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            {(currentUser?.isAdmin || (currentUser?.isSubAdmin && currentUser?.subAdmin?.permissions?.i_courses_classes?.edit)) && <MenuItem onClick={() => { handleOpenEditClass() }} >Edit</MenuItem>}
            {(currentUser?.isAdmin || (currentUser?.isSubAdmin && currentUser?.subAdmin?.permissions?.i_courses_classes?.delete)) && <MenuItem onClick={() => handleOpenModalDeleteClass()} >Delete</MenuItem>}
          </Menu>
        </>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="I-Course - Classes"
        subtitle="Managing the Classes of I-Course"
      />
      {/* Modal */}
      <AddNewClass categoriesOptions={categoriesOptions} open={openNewClass} handleClose={handleCloseModalNewUnivesity} />
      <DeleteClassModal selectedClass={selectedClass} open={openDeleteClass} handleClose={handleCloseModalDeleteClass} />
      <EditClassModel selectedClass={selectedClass} open={openEditClass} handleClose={handleCloseEditClass} />
      {/* open, handleClose, universityId */}

      <Box display="flex" flexDirection="row" gap={2} sx={{ width: '100%' }}>


        {(currentUser?.isAdmin || (currentUser?.isSubAdmin && currentUser?.subAdmin?.permissions?.i_courses_classes?.create)) && <Button
          variant="contained"
          sx={{ backgroundColor: '#0093e6', color: '#fff' }}
          onClick={() => handleOpenModalNewUnivesity()}
        >
          Add New Class
        </Button>}

        <SelectComponent
          label="Select a Category"
          width="25%"
          value={selectedCategory}
          onChange={handleCategoryChange}
          options={categoriesOptions}
        />

        <SelectComponent
          label="Select a Course"
          width="25%"
          value={selectedCourse}
          onChange={handleCourseChange}
          options={categoriesCourses}
        />

        <SelectComponent
          label="Select a Chapters"
          width="25%"
          value={selectedChapter}
          onChange={handleChaptersChange}
          options={chaptersOptions}
        />


      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >


        <DataGrid
          loading={loading}
          checkboxSelection
          columns={columns}
          rows={classes || []}
          getRowId={(row) => row._id}
        />




      </Box>
    </Box>
  );
};

export default IcourseClasses;