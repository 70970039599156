import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from './ApiService';

const apiService = new ApiService();

const initialState = {
    data: [],
    loading: false,
    totalRecords: 0,
    error: null,
    nextUrl: null,
    previousUrl: null,
    currentUrl: null,
    walletLoading: null,
    walletError: null,
    walletSuccessfully: null,
    assignTeacherLoading: null,
    assignTeacherError: null,
    assignTeacherSuccessfully: null,
    deleteUserSuccessfully: null,
    deleteUserError: null,
    reactivatedUserSuccessfully: null,
    reactivatedUserError: null,
    removeTeacherSuccessfully: null,
    removeTeacherError: null,
    userDetails: {},
    userDetailsLoading: false,
    errorGetUserDetails: null,
    currentUser: null,
    currentUserLoading: false,
    currentUserError: null,

    allUsers: [],
    allUsersLoading: false,
    allUsersError: null,

    userSetSubAdmin: null,
    userSetSubAdminLoading: false,
    userSetSubAdminError: null,
    userSetSubAdminSuccessfully: false,

    userGetSubAdmin: null,
    userGetSubAdminLoading: false,
    userGetSubAdminError: null,
    userGetSubAdminSuccessfully: false,
};


export const getUsers = createAsyncThunk(
    'admin/users',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService.get('/admin/users');
            return response.data;
        } catch (error) {
            console.log("response", error);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const usersSearch = createAsyncThunk(
    'admin/search-users',
    async (keyword, { rejectWithValue }) => {
        try {
            const response = await apiService.get(`/admin/search-users?keyword=${keyword}`);
            return response.data;
        } catch (error) {
            console.log("response", error);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getNextData = createAsyncThunk(
    'admin/users/next-data',
    async ({ limit, offset }, { rejectWithValue }) => {
        try {
            const response = await apiService.get(`/admin/users?limit=${limit}&offset=${offset}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


export const assignToWallet = createAsyncThunk(
    '/payment/assign-to-wallet',
    async (data, { rejectWithValue }) => {
        console.log("data", data);
        try {
            const response = await apiService.post('/payment/assign-to-wallet', data);

            return response.data;
        } catch (error) {
            console.log("response", error);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


export const assignTeacher = createAsyncThunk(
    '/teachers/assign',
    async (data, { rejectWithValue }) => {
        try {
            console.log("data assign teacher", data);

            const response = await apiService.post('/teachers/assign', data);
            return response.data;
        } catch (error) {
            console.log("error.response?.data ", error.response?.data.message);
            console.log("error.message", error.message);
            return rejectWithValue(error.response?.data.message || { message: error.message });
        }
    }
);

export const handleUserSubAdmin = createAsyncThunk(
    '/users/set-sub-admin',
    async ({userId, data}, { rejectWithValue }) => {
        try {
            console.log("data assign teacher", data);

            const response = await apiService.post(`/users/set-sub-admin?userId=${userId}`, data);

            return response.data;
        } catch (error) {
            console.log("error.response?.data ", error.response?.data.message);
            console.log("error.message", error.message);
            return rejectWithValue(error.response?.data.message || { message: error.message });
        }
    }
);


export const removeTeacher = createAsyncThunk(
    '/teachers/delete',
    async ({ userId }, { rejectWithValue }) => {
        try {
            console.log("userId", userId)
            const response = await apiService.delete(`/teachers/delete-teacher?userId=${userId}`,);
            return response.data;
        } catch (error) {
            console.log("error.response?.data ", error.response?.data.message);
            console.log("error.message", error.message);
            return rejectWithValue(error.response?.data.message || { message: error.message });
        }
    }
);

export const deleteUserById = createAsyncThunk(
    '/users/delete',
    async ({ userId }, { rejectWithValue }) => {
        try {
            const response = await apiService.delete(`/users/delete-user?userId=${userId}`);

            return response.data;

        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const reactivatedUserById = createAsyncThunk(
    '/users/reactivated',
    async ({ userId }, { rejectWithValue }) => {
        try {
            const data = { userId };
            const response = await apiService.put(`/users/re-activate-user`, data);
            return response.data;
        } catch (error) {
            console.error("Error reactivating user:", error);

            if (!error.response) {
   
                console.error("Network error:", error.message);
                return rejectWithValue({ message: "Network error. Please try again later." });
            }

            console.error("Server error:", error.response.data);
            return rejectWithValue(error.response.data || { message: error.message });
        }
    }
);

export const getUserDetailsById = createAsyncThunk(
    '/users/user-data',
    async ({ userId }, { rejectWithValue }) => {
        try {

            const response = await apiService.get(`/users/user-data?userId=${userId}`);
            return response.data;

        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getSubAdminByUserId = createAsyncThunk(
    '/users/get-sub-admin',
    async (userId, { rejectWithValue }) => {
        try {

            const response = await apiService.get(`/users/get-sub-admin?userId=${userId}`);
            return response?.data;

        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getCurrentUser = createAsyncThunk(
    '/users/current-user',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService.get('/users/currentUser');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getAllUsersNotInGroup = createAsyncThunk(
    '/groups/users-not-in-group',
    async (groupId, { rejectWithValue }) => {
        try {
            const response = await apiService.get(`/groups/users-not-in-group?groupId=${groupId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


const updateFulfilledState = (state, action) => {
    state.loading = false;
    state.data = action.payload.items;
    state.msg = action.payload.message;
    state.totalRecords = action.payload.links.totalRecord;
    state.nextUrl = action.payload.links.next;
    state.previousUrl = action.payload.links.previous;
    
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.walletSuccessfully =false;
                state.walletError = false;
                state.assignTeacherSuccessfully = false;
                state.assignTeacherError = false;
                state.deleteUserSuccessfully = false;
                state.deleteUserError = false;
                state.reactivatedUserSuccessfully = false;
                state.reactivatedUserError = false;
                state.removeTeacherSuccessfully = false;
                state.removeTeacherError = false;
                state.userDetails = {};
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                updateFulfilledState(state, action)
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(getNextData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getNextData.fulfilled, (state, action) => {
                updateFulfilledState(state, action);
            })
            .addCase(getNextData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(usersSearch.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(usersSearch.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.data = action.payload.items
            })
            .addCase(usersSearch.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(assignToWallet.pending, (state) => {
                state.walletLoading = true;
                state.walletError = null;
                state.walletSuccessfully = false;
            })
            .addCase(assignToWallet.fulfilled, (state, action) => {
                state.walletLoading = false;
                state.walletError = null;
                state.walletSuccessfully = true;
            })
            .addCase(assignToWallet.rejected, (state, action) => {
                state.walletLoading = false;
                state.walletError = action.payload.message;
                state.walletSuccessfully = false;
            })
            .addCase(assignTeacher.pending, (state) => {
                state.assignTeacherLoading = true;
                state.assignTeacherError = null;
                state.assignTeacherSuccessfully = false;
            })
            .addCase(assignTeacher.fulfilled, (state, action) => {
                state.assignTeacherLoading = false;
                state.assignTeacherError = null;
                state.assignTeacherSuccessfully = true;
            })
            .addCase(assignTeacher.rejected, (state, action) => {
                state.assignTeacherLoading = false;
                state.assignTeacherError = true;
                state.assignTeacherSuccessfully = false;
            })
            .addCase(deleteUserById.pending, (state) => {
                state.loading = true;
                state.deleteUserError = null;
                state.deleteUserSuccessfully = false;
            })
            .addCase(deleteUserById.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteUserError = null;
                state.deleteUserSuccessfully = true;
            })
            .addCase(deleteUserById.rejected, (state, action) => {
                state.loading = false;
                state.deleteUserError = action.payload.message;
                state.deleteUserSuccessfully = false;
            })
            .addCase(reactivatedUserById.pending, (state) => {
                state.loading = true;
                state.reactivatedUserError = null;
                state.reactivatedUserSuccessfully = false;
            })
            .addCase(reactivatedUserById.fulfilled, (state, action) => {
                state.loading = false;
                state.reactivatedUserError = null;
                state.reactivatedUserSuccessfully = true;
            })
            .addCase(reactivatedUserById.rejected, (state, action) => {
                state.loading = false;
                state.reactivatedUserError = action.payload.message;
                state.reactivatedUserSuccessfully = false;
            })
            .addCase(removeTeacher.pending, (state) => {
                state.loading = true;
                state.removeTeacherError = null;
                state.removeTeacherSuccessfully = false;
            })
            .addCase(removeTeacher.fulfilled, (state, action) => {
                state.loading = false;
                state.removeTeacherError = null;
                state.removeTeacherSuccessfully = true;
            })
            .addCase(removeTeacher.rejected, (state, action) => {
                state.loading = false;
                state.removeTeacherError = true
                state.removeTeacherSuccessfully = false;
            })
            .addCase(getUserDetailsById.pending, (state) => {
                state.userDetailsLoading = true;
                state.errorGetUserDetails = null;
            })
            .addCase(getUserDetailsById.fulfilled, (state, action) => {
                state.userDetailsLoading = false;
                state.errorGetUserDetails = null;
                state.userDetails = action.payload;
            })
            .addCase(getUserDetailsById.rejected, (state, action) => {
                state.userDetailsLoading = false;
                state.errorGetUserDetails = action.payload.message;
            })
            .addCase(getCurrentUser.pending, (state) => {
                state.currentUserLoading = true;
                state.currentUserError = null;
            })
            .addCase(getCurrentUser.fulfilled, (state, action) => {
                state.currentUserLoading = false;
                state.currentUserError = null;
                state.currentUser = action.payload;
            })
            .addCase(getCurrentUser.rejected, (state, action) => {
                state.currentUserLoading = false;
                state.currentUserError = action.payload.message;
            })

            .addCase(getAllUsersNotInGroup.pending, (state) => {
                state.allUsersLoading = true;
                state.allUsersError = null;
            })
            .addCase(getAllUsersNotInGroup.fulfilled, (state, action) => {
                state.allUsersLoading = false;
                state.allUsersError = null;
                state.allUsers = action.payload;
            })
            .addCase(getAllUsersNotInGroup.rejected, (state, action) => {
                state.allUsersLoading = false;
                state.allUsersError = action.payload.message;
            })

            .addCase(handleUserSubAdmin.pending, (state) => {
                state.userSetSubAdminLoading = true;
                state.userSetSubAdminError = null;
                state.userSetSubAdminSuccessfully = false;
            })
            .addCase(handleUserSubAdmin.fulfilled, (state, action) => {
                state.userSetSubAdminLoading = false;
                state.userSetSubAdminError = null;
                state.userSetSubAdmin = action.payload;
                state.userSetSubAdminSuccessfully = true;
            })
            .addCase(handleUserSubAdmin.rejected, (state, action) => {
                state.userSetSubAdminLoading = false;
                state.userSetSubAdminError = action.payload.message;
                state.userSetSubAdminSuccessfully = false;
            })

            .addCase(getSubAdminByUserId.pending, (state) => {
                state.userGetSubAdminLoading = true;
                state.userGetSubAdminError = null;
                state.userGetSubAdminSuccessfully = false;
            })
            .addCase(getSubAdminByUserId.fulfilled, (state, action) => {
                state.userGetSubAdminLoading = false;
                state.userGetSubAdminError = null;
                state.userGetSubAdmin = action.payload;
                state.userGetSubAdminSuccessfully = true;
            })
            .addCase(getSubAdminByUserId.rejected, (state, action) => {
                state.userGetSubAdminLoading = false;
                state.userGetSubAdminError = action.payload?.message;
                state.userGetSubAdminSuccessfully = false;
            })
    },
});

export const { logout } = usersSlice.actions;

export default usersSlice.reducer;
