import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import { Button, Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarMsg, { Severity, AnchorOrigin } from '../SnackbarMsg';
import { useEffect, useState } from 'react';
import { getSubAdminByUserId, getUsers, handleUserSubAdmin } from '../../redux/userslice';

export default function SetUserAsSubAdmin({ selectedUser, open, handleClose }) {
    const dispatch = useDispatch();

    const { userSetSubAdminLoading, userSetSubAdminError, userSetSubAdminSuccessfully, userGetSubAdmin, userGetSubAdminSuccessfully } = useSelector((state) => state.users);
    const [userId, setUserId] = useState(null);
    const [permissions, setPermissions] = useState({
        users: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        groups: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        universities: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        collages: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        departments: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        materials: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        requested_groups: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        i_courses_categories: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        i_courses_courses: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        i_courses_chapters: { view: false, create: false, edit: false, delete: false, fullAccess: false },
        i_courses_classes: { view: false, create: false, edit: false, delete: false, fullAccess: false },
    });

    const sections = [
        'users', 'groups', 'universities', 'collages', 'departments',
        'materials', 'requested_groups', 'i_courses_categories', 'i_courses_courses',
        'i_courses_chapters', 'i_courses_classes'
    ];

    useEffect(() => {
        setUserId(selectedUser?._id);
    }, [selectedUser]);

    useEffect(() => {
        setPermissions(
            {
                users: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                groups: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                universities: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                collages: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                departments: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                materials: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                requested_groups: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                i_courses_categories: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                i_courses_courses: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                i_courses_chapters: { view: false, create: false, edit: false, delete: false, fullAccess: false },
                i_courses_classes: { view: false, create: false, edit: false, delete: false, fullAccess: false },
            }
        )
        dispatch(getSubAdminByUserId(selectedUser?._id));
    }, [selectedUser]);

    useEffect(() => {
        if (userGetSubAdminSuccessfully && userGetSubAdmin) {
            setPermissions({ ...permissions, ...userGetSubAdmin?.permissions })
        }
    }, [userGetSubAdminSuccessfully, userGetSubAdmin])

    const handleSubmit = () => {
        if (userId && permissions) {
            const data = {
                permissions,
            }

            const dataToSubmit = {
                userId,
                data,
            };

            dispatch(handleUserSubAdmin(dataToSubmit)).then(() => {
                dispatch(getUsers());
                handleClose();
            })
        }
    };

    const handleCheckboxChange = (section, permission) => {
        setPermissions((prevPermissions) => {
            const updatedPermissions = { ...prevPermissions };

            updatedPermissions[section] = { ...updatedPermissions[section] };

            if (permission === 'fullAccess') {
                updatedPermissions[section] = {
                    view: !updatedPermissions[section][permission],
                    create: !updatedPermissions[section][permission],
                    edit: !updatedPermissions[section][permission],
                    delete: !updatedPermissions[section][permission],
                    fullAccess: !updatedPermissions[section][permission],
                };
            } else {
                updatedPermissions[section][permission] = !updatedPermissions[section][permission];
                if (!updatedPermissions[section][permission]) {
                    updatedPermissions[section].fullAccess = false;
                }
            }

            return updatedPermissions;
        });
    };

    const FullAccessCheckbox = styled(Checkbox)(({ theme }) => ({
        '&.Mui-checked': {
            color: '#28a745',
        },
        '& .MuiSvgIcon-root': {
            borderRadius: 4,
        },
        '&:hover': {
            backgroundColor: 'rgba(40, 167, 69, 0.2)',
        },
    }));


    return (
        <div>
            {userSetSubAdminError && <SnackbarMsg text="Set User as sub-admin Failed" severity={Severity.ERROR} anchorOrigin={AnchorOrigin.BOTTOM_LEFT} />}
            {userSetSubAdminSuccessfully && <SnackbarMsg text="Set User as sub-admin Successfully" severity={Severity.SUCCESS} anchorOrigin={AnchorOrigin.BOTTOM_LEFT} />}

            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={open}
                onClose={handleClose}
                slots={{ backdrop: StyledBackdrop }}
            >
                <ModalContent sx={{ width: 400 }}>
                    <Typography variant="h6" sx={{ mb: 2, color: '#fff' }}>
                        Set the sections roles to set user as sub-admin
                    </Typography>

                    <Box
                        sx={{
                            maxHeight: 300,
                            overflowY: 'auto',
                            mb: 0,
                            px: 1,
                        }}
                    >
                        <Box sx={{ mb: 2 }}>
                            {sections.map((section) => (
                                <Box key={section} sx={{ mb: 2 }}>
                                    <Typography variant="subtitle1" sx={{ color: '#fff' }}>
                                        {section.charAt(0).toUpperCase() + section.slice(1).replaceAll('_', ' ')} Section
                                    </Typography>
                                    {['view', 'create', 'edit', 'delete', 'fullAccess'].map((permission) => (
                                        <FormControlLabel
                                            key={permission}
                                            control={
                                                <FullAccessCheckbox
                                                    checked={permissions[section][permission]}
                                                    onChange={() => handleCheckboxChange(section, permission)}
                                                />
                                            }
                                            label={permission === 'fullAccess' ? 'Full Access' : permission.charAt(0).toUpperCase() + permission.slice(1)}
                                        />
                                    ))}
                                </Box>
                            ))}
                        </Box>
                        <Box display="flex" justifyContent="space-evenly">
                            <Button
                                variant="contained"
                                style={{
                                    width: '120px',
                                    textAlign: 'center', backgroundColor: 'green', color: 'white'
                                }}
                                onClick={handleSubmit}
                                disabled={userSetSubAdminLoading}
                            >
                                {userSetSubAdminLoading ? 'Saving...' : 'Save'}
                            </Button>

                            <Button
                                style={{
                                    width: '120px',
                                    textAlign: 'center'
                                }}
                                variant="contained"
                                sx={{ backgroundColor: '#0093e6' }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </ModalContent>
            </Modal>
        </div>
    );
}

SetUserAsSubAdmin.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedUser: PropTypes.object.isRequired,
};

const grey = {
    50: '#F3F6F9',
    900: '#191E23',
};

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div className={clsx({ 'base-Backdrop-open': open }, className)} ref={ref} {...other} />
    );
});

Backdrop.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
    ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
  `
);
