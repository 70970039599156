import { Box, IconButton, Button } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddNewCourseModal from "../../components/i-course/courses/AddNewICourse";
import { coursesList, categoryList } from "../../redux/icourseslice";
import SelectComponent from "../../components/Select"
import EditCourseModel from "../../components/i-course/courses/EditICourse"
import DeleteCourseModel from "../../components/i-course/courses/DeleteICourse"

const IcourseCourses = () => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  const [anchorEl, setAnchorEl] = useState(null);
  const { loading, categories, totalRecords, courses, addNewCourseSuccessfully, editCourseSuccessfully, deleteCourseSuccessfully } = useSelector((state) => state.icourse);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [openNewCourse, setOpenNewCourse] = useState(false);
  const [openEditCourse, setOpenEditCourse] = useState(false);
  const [opneDeleteCourse, setOpneDeleteCourse] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { currentUser } = useSelector((state) => state.users);

  useEffect(() => {
    document.title = "I Course | Admin Panel";
    dispatch(categoryList());
    setPaginationModel({
      pageSize: courses?.length || 10,
      page: 0,
    });

  }, [])



  const handleCategoryChange = (event) => {
    const selectedUniId = event.target.value;
    setSelectedCategory(selectedUniId);
  };

  useEffect(() => {
    if (selectedCategory) {
      dispatch(coursesList({ selectedCategory }));
    }
  }, [selectedCategory])

  const categoriesOptions = (categories || []).map((category) => ({
    value: category?._id,
    label: category?.title,
  }));

  useEffect(() => {

    if (selectedCategory && (addNewCourseSuccessfully === true || editCourseSuccessfully === true || deleteCourseSuccessfully === true)) {
      dispatch(coursesList({ selectedCategory }));
    }

  }, [addNewCourseSuccessfully, editCourseSuccessfully, deleteCourseSuccessfully])

  const handleMenuClick = (event, university) => {
    setSelectedUniversity(null);
    setAnchorEl(event.currentTarget);
    setSelectedUniversity(university);
  };

  const handlePaginations = (model) => {
    const offset = model.page * model.pageSize;
    // dispatch(getNextUniversityData({ limit: model.pageSize, offset }));
    setPaginationModel(model);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };



  const handleCloseModalNewCourse = () => {
    setOpenNewCourse(false);
  }

  const handleOpenModalNewCourse = () => {
    setOpenNewCourse(true);
  }

  const handleOpenModalEditCourse = () => {
    setOpenEditCourse(true);
    handleMenuClose()
  }

  const handleCloseModalEditCourse = () => {
    setOpenEditCourse(false);
  }



  const handleCloseModalDeleteCourse = () => {
    setOpneDeleteCourse(false);

  }

  const handleOpenModalDeleteCourse = () => {
    setOpneDeleteCourse(true);
    handleMenuClose()
  }

  const handleSearch = () => {

  }

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "title",
      headerName: "title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "type",
      headerName: "Type",
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "status",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "coverUrl",
      headerName: "cover",
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Photo"
          style={{ width: '20%', height: 'auto', objectFit: 'cover' }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => {
            event.stopPropagation();
            handleMenuClick(event, params.row)
          }}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            {(currentUser?.isAdmin || (currentUser?.isSubAdmin && currentUser?.subAdmin?.permissions?.i_courses_courses?.edit))&&<MenuItem onClick={() => { handleOpenModalEditCourse() }} >Edit</MenuItem>}
           {(currentUser?.isAdmin || (currentUser?.isSubAdmin && currentUser?.subAdmin?.permissions?.i_courses_courses?.delete))&& <MenuItem onClick={() => handleOpenModalDeleteCourse()} >Delete</MenuItem>}
          </Menu>
        </>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="I-Course - Courses"
        subtitle="Managing the Courses"
      />
      {/* Modal */}
      <AddNewCourseModal categoriesOptions={categoriesOptions} open={openNewCourse} handleClose={handleCloseModalNewCourse} />
      <EditCourseModel selectedCourse={selectedUniversity} open={openEditCourse} handleClose={handleCloseModalEditCourse} />
      <DeleteCourseModel selectedCourse={selectedUniversity} open={opneDeleteCourse} handleClose={handleCloseModalDeleteCourse} />

      {/* open, handleClose, universityId */}

      <Box display="flex" flexDirection="row" gap={2} sx={{ width: '100%' }}>

        {(currentUser?.isAdmin || (currentUser?.isSubAdmin && currentUser?.subAdmin?.permissions?.i_courses_courses?.create))&&<Button
          variant="contained"
          sx={{ backgroundColor: '#0093e6', color: '#fff' }}
          onClick={() => handleOpenModalNewCourse()}
        >
          Add New Course
        </Button>}

        <SelectComponent
          label="Select a Category"
          width="25%"
          value={selectedCategory}
          onChange={handleCategoryChange}
          options={categoriesOptions}
        />
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >

        <DataGrid
          checkboxSelection
          rows={courses || []}
          columns={columns}
          pageSize={10}
          rowCount={totalRecords}
          getRowId={(row) => row._id}
          paginationMode="server"
          loading={loading}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => {
            handlePaginations(model);
          }}
        />

      </Box>
    </Box>
  );
};

export default IcourseCourses;